export const avatars = {
    'boys':[
        '002-boy-1.png',
        '003-boy-6.png',
        '004-boy.png',
        '005-son.png',
        '006-boy-2.png',
        '007-boy-3.png',
        '008-boy-4.png',
        '009-boy-5.png',
        '010-man.png',
        'boy-1.png',
        'boy-2.png',
        'boy-3.png',
        'boy-4.png',
        'boy-5.png',
        'boy-6.png',
        'boy-7.png',
        'boy-8.png',
        'boy-9.png',
        'boy-10.png',
        'boy-11.png',
        'boy-12.png',
        'boy-13.png',

        'man-1.png',
        'man-2.png',
        'man-3.png',
        'man-4.png',
        'man-5.png',
        'man-6.png',
        'man-7.png',
        'man-8.png',
        'man-9.png',
        'man-10.png',
        'man-11.png',
        'man-12.png',
        'man-13.png',
        'man-14.png',
        'man-15.png',
        'man-16.png',
        'man-17.png',
        'man-18.png',
        'man-19.png',
        'man-20.png',
        'man-21.png',
        'man-22.png',
        'man-23.png',
        'man-24.png',
        'man-25.png',
        'man-26.png',
        'man-27.png',
        'man-28.png',
        'man-29.png',
        'man-30.png',
        'man-31.png',
        'man-32.png',
        'man-33.png',
        'man-34.png',
        'man-35.png',
        'man-36.png',
        'man-37.png',
        'man-38.png',
        'man-39.png',
        'man-40.png',


    ],
    'girls':[
        '001-girl.png',
        '001-happiness.png',
        '002-girl.png',
        '003-girl-1.png',
        '004-girl-2.png',
        '005-girl-3.png',
        '006-happiness-1.png',
        '007-girl-4.png',
        '008-girl-5.png',
        '009-girl-6.png',
        'girl-1.png',
        'girl-2.png',
        'girl-3.png',
        'girl-4.png',
        'girl-5.png',
        'girl-6.png',
        'girl-7.png',
        'girl-8.png',
        'girl-9.png',
        'girl-10.png',
        'girl-11.png',
        'girl-12.png',
        'girl-13.png',
        'girl-14.png',

        'woman-1.png',
        'woman-2.png',
        'woman-3.png',
        'woman-4.png',
        'woman-5.png',
        'woman-6.png',
        'woman-7.png',
        'woman-8.png',
        'woman-9.png',
        'woman-10.png',
        'woman-11.png',
        'woman-12.png',
        'woman-13.png',
        'woman-14.png',
        'woman-15.png',
        'woman-16.png',
        'woman-17.png',
        'woman-18.png',
        'woman-19.png',
        'woman-20.png',
        'woman-21.png',
        'woman-22.png',
        'woman-23.png',
        'woman-24.png',
        'woman-25.png',
        'woman-26.png',
        'woman-27.png',
        'woman-28.png',
        'woman-29.png',
        'woman-30.png',
        'woman-31.png',
        'woman-32.png',
        'woman-33.png',
        'woman-34.png',

    ]
}