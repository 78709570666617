<template>
  <div class="container" style="margin-top:70px;">
    <div style="display:flex;">
      <back-button />
      <h5 style="margin:auto;">{{pageName}}</h5>
    </div>
    <hr>

    <form @submit.prevent="handleFormSubmission" >
      <div class="form-group">
        <label for="parentalRole" class="required">Parental Role</label>
        <select name="parentalRole" as="select"  class="form-control" v-model="parentalRole">
          <option value="Dad">Dad</option>
          <option value="Mom">Mom</option>
          <option value="Guardian">Guardian</option>
          <option value="0">None (Kid)</option>
        </select>
      </div>


        <div class="form-group">
          <label for="accountname" class="required">First Name or Nick Name</label>
          <input name="accountname" type="text" class="form-control" v-model="userName" />
          <ul class="error-feedback" v-if="accountnameErrors.length > 0" v-html="accountnameErrors"></ul>
        </div>




        <div class="form-group">
          <label for="age" class="required">Age</label>
          <div class="input-group mb-3">
            <span class="input-group-text" @click="ageAdj('minus')"><font-awesome-icon :icon="['fas', 'minus-circle']" /></span>
            <input name="age" type="text" class="form-control" v-model="age" />
            <span class="input-group-text"  @click="ageAdj('plus')"><font-awesome-icon :icon="['fas', 'plus-circle']" /></span>
          </div>
          <ul class="error-feedback" v-if="ageErrors.length > 0" v-html="ageErrors"></ul>
        </div>

        <div class="form-group">
          <label for="sex" class="required">Gender</label>
          <select name="sex" as="select"  class="form-control" v-model="sex" @change="getAvatars()">
            <option>-Select One-</option>
            <option value="m">Boy</option>
            <option value="f">Girl</option>
          </select>
          <ul class="error-feedback" v-if="sexErrors.length > 0" v-html="sexErrors"></ul>
        </div>


      <div class="form-group avatarDiv" v-if="this.sex">
        <label>Avatar <span class="credits" @click="this.$root.gotoRoute({ path: '/settings/about'})"><font-awesome-icon :icon="['fas', 'info-circle']" /></span></label>
        <carousel :items-to-show="1" :transition="100" :wrap-around="true" v-model="avatarIdx" >
          <slide v-for="(avatar,idx) in this.avatars" :key="idx" >
            <div class="carousel__item"><img :src="'/images/avatar/'+folderName+'/'+avatar"></div>
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>
      </div>

      <div class="form-group" v-if="!this.userId">
        <label for="initialBalance" class="required">Initial Balance</label>
        <input name="initialBalance" type="text" class="form-control" v-model="initialBalance" />
        <ul class="error-feedback" v-if="initialBalanceErrors.length > 0" v-html="initialBalanceErrors"></ul>
      </div>

      <div class="form-group">
        <label for="savingGoal" class="required">Saving Goal</label>
        <input name="savingGoal" type="text" class="form-control" v-model="savingGoal" />
        <ul class="error-feedback" v-if="savingGoalErrors.length > 0" v-html="savingGoalErrors"></ul>
      </div>

      <div class="form-group">
        <label for="showInBalanceChart" class="required">Show on Balance Chart?</label>
        <select class="form-control" id="showInBalanceChart" name="FamilyAdmin" v-model="showInBalanceChart" :disabled="isAdmin==='0'">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

      <div class="form-group">
        <label for="FamilyAdmin" class="required">Is this user a family admin?</label>
        <select class="form-control" id="FamilyAdmin" name="FamilyAdmin" v-model="isFamilyAdmin" :disabled="isAdmin==='0' || userId === userInfo.ID">
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>

      <div class="form-group">
        <label for="CreateLogin" class="required">Would you like to create a login for this member? (Email will be required.)</label>
        <select class="form-control" id="CreateLogin" name="CreateLogin" v-model="createLogin" :disabled="isAdmin==='0' || userId === userInfo.ID">
          <option value="0">No</option>
          <option value="1">Yes</option>
        </select>
      </div>

      <div class="form-group" v-if="createLogin2">
        <label for="Email">Email</label>
        <input name="email" type="email" class="form-control" v-model="email" :disabled="isAdmin==='0'" />
        <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>
        <small id="emailHelp" class="form-text text-muted">Providing email address here will create a user login account for this family member, so they can log in themselves. They will receive an email invitation with their temporary password to log in.</small>
      </div>

      <div class="form-group" v-if="createLogin2">
        <label for="Email">Allow member to view balances of the other family members</label>
        <select class="form-control" id="viewOthers" name="viewOthers" v-model="viewOthers" :disabled="isAdmin==='0' || userId === userInfo.ID">
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                  ></span>
          {{ submitBtnTxt }}
        </button>
        <button class="btn btn-danger btn-block delBtn" @click="showDelModalFunc" v-if="userId">Delete User</button>
      </div>
    </form>

    <div id="errorDiv" v-if="errorMsg.length > 0">
      {{errorMsg}}
    </div>




  </div>

  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Are you sure you want to delete this family member?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes clickable" @click="delUser"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo clickable" @click="closeDelModal"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>

        <div v-if="noadminleftwarning" style="margin-top:20px;font-size:11px;color:red;">
          You can not delete yourself, because you are the only family admin left in your family. You can choose to delete your entire family in the Family Settings though.
        </div>
      </div>
    </vue-final-modal>
  </div>

</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {avatars} from "@/services/avatar";
import {VueFinalModal } from 'vue-final-modal';


export default {
  name: 'FamilyUser',
  components: {
    Carousel,
    Slide,
    Navigation,
    VueFinalModal
  },
  data(){

    return {
      userId:null,
      userInfo:{},
      isAdmin:'0',
      pageMode:'new',
      pageName: '',
      successful: false,
      loading: false,
      message: "",
      errorMsg: "",
      userName:'',
      initialBalance:'100',
      savingGoal:'100',
      age:'5',
      sex:null,
      parentalRole:'0',
      folderName:null,
      scrCheckParent:true,
      scrNotParent:false,
      scrRegForm:false,
      isFamilyAdmin:'0',
      createLogin:'0',
      viewOthers:'1',
      showInBalanceChart:'1',
      email:'',
      avatarsAll:avatars,
      avatars:[],
      avatar:null,
      avatarIdx:0,
      avatarsLoaded:false,
      submitBtnTxt:'',

      hasValidationError: false,
      accountnameErrors:'',
      ageErrors:'',
      initialBalanceErrors:'',
      savingGoalErrors:'',
      sexErrors:'',
      emailErrors:'',

      formData:{},

      showDeleteModal:false,
      noadminleftwarning:false
    };
  },
  computed:{
    createLogin2(){
      return this.createLogin === '1';
    },
  },
  methods:{
    getUser: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getUser', userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            const userData = response.data;
            this.pageName = 'Edit Family Member - ' + userData.FULL_NAME;
            this.userName = userData.FULL_NAME;
            this.age = userData.AGE || '5';
            this.sex = userData.SEX;
            this.parentalRole = userData.PARENT_ROLE;
            this.isFamilyAdmin = userData.FAMILY_ADMIN;
            this.email = userData.EMAIL;
            this.viewOthers = userData.VIEW_OTHERS;
            this.avatar = userData.AVATAR_URL;
            this.showInBalanceChart = userData.SHOW_IN_BALANCE_CHART;
            if(this.email && this.email.length > 8){
              this.createLogin = '1';
            }
            this.getAvatars();
            /*if(this.avatar !== '001-girl.png' && this.avatar !== '002-boy-1.png' ) this.avatars.unshift(userData.AVATAR_URL);
            this.avatar = this.avatars[0];*/
            this.avatarIdx = this.avatars.indexOf(userData.AVATAR_URL);
            this.avatar = this.avatars[this.avatarIdx];

            loader.hide();

          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },

    delUser:async function(){
      this.$root.playClickSound();
      let response = await this.axios
          .post(this.$hostApi,{task:'delUser', userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      if(response.data.res === 'noadminleft'){
        this.noadminleftwarning = true;
      }else{
        this.showDeleteModal = false;
        localStorage.removeItem('balanceChartData');
        await this.$root.doRefresh();
        this.$root.gotoRoute({ path: '/settings/family/'});
      }
    },

    ageAdj:function(dir){
      if(dir==='plus'){
        //console.log('plus');
        this.age = parseInt(this.age) + 1;
      }
      if(dir==='minus'){
        this.age = Math.max(parseInt(this.age) - 1,1);
      }

    },

    handleAddNewMember: async function() {
      this.errorMsg = "";
      let res = await this.axios
          .post(this.$hostApi,{task:'addNewMemberCreateDefaultBankAccounts', data:this.formData},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      if(res.data.userId === 'userExists'){
        console.log('here1');
        this.errorMsg = "This email already has a registered account. Please use another email of the user.";
      }else{
        console.log('here2');
        this.message = 'User has been added.';
        this.successful = true;
        //localStorage.removeItem('balanceChartData');
        await this.$root.doRefresh();
        this.$root.gotoRoute({ path: '/settings/family/'});

      }

      this.loading = false;

    },

    handleUpdateMember: async function() {
      let response = await this.axios
          .post(this.$hostApi,{task:'updateUserInfo', data:this.formData, userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      if(response.data === 'emailused'){
        this.emailErrors += "<li>This email has already been used by another user.</li>";
        this.hasValidationError = true;
      }else{
        this.successful = true;
        localStorage.removeItem('balanceChartData');
        await this.$root.doRefresh();
        this.$root.gotoRoute({ path: '/settings/family/'});
      }

      this.loading = false;

    },

    handleFormSubmission(){
      this.$root.playClickSound();

      this.message = "";
      this.successful = false;
      this.loading = true;
      this.hasValidationError = false;

      let d = new Date();
      this.formData = {
        'accountname':this.userName.trim(),
        'email':this.email.trim(),
        'age':this.age,
        'sex':this.sex,
        'parentalRole': this.parentalRole,
        'showInBalanceChart':this.showInBalanceChart,
        'initialBalance':this.initialBalance,
        'savingGoal':this.savingGoal,
        'viewOthers':this.viewOthers,
        'tranDate':d.toLocaleDateString('en-CA')
      };

      this.formData.FAMILY_ADMIN = this.isFamilyAdmin;
      this.formData.avatar = this.avatars[this.avatarIdx];

      this.validateAll();

      if(!this.hasValidationError){
        if(this.userId){
          this.handleUpdateMember();
        }else{
          this.handleAddNewMember();
        }
      }else{
        this.loading = false;
      }



    },

    getAvatars(){
      this.avatarsLoaded = false;
      this.avatarsAll = JSON.parse(JSON.stringify(avatars));
      //console.log(this.sex);
      if(this.sex === 'f'){
        this.avatars = this.avatarsAll.girls;
        this.folderName = 'girl';
      }else if(this.sex === 'm'){
        this.avatars = this.avatarsAll.boys;
        this.folderName = 'boy';
      }else{
        this.avatars = [];
        this.folderName = '';
      }

      //console.log(this.folderName);

    },


    validateAccountName(){
      this.accountnameErrors = this.$root.validateLength(this.userName,3,20);
      if(this.accountnameErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateEmail() {
      if(this.createLogin2){
        let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
        errors += this.$root.validateLength(this.email,0,100);
        this.emailErrors = errors;

        if(this.emailErrors.length > 0){
          this.hasValidationError = true;
        }
      }

    },


    validateAge(){
      this.ageErrors = this.$root.validateLength(this.age,1,3);
      if(parseInt(this.age) < 1 || parseInt(this.age) > 150){
        this.ageErrors += '<li>age value is not valid</li>';
      }
      if(this.ageErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateSex(){
      this.sexErrors = this.$root.validateLength(this.sex,0,2);
      if(this.sexErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateInitialBalance(){
      this.initialBalanceErrors = this.$root.validateLength(this.initialBalance,0,10);
      if(isNaN(this.initialBalance)) this.initialBalanceErrors += "<li>value must be a number.</li>";
      if(this.initialBalanceErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateSavingGoal(){
      this.savingGoalErrors = this.$root.validateLength(this.savingGoal,0,10);
      if(isNaN(this.savingGoal)) this.savingGoalErrors += "<li>value must be a number.</li>";
      if(this.savingGoalErrors.length > 0){
        this.hasValidationError = true;
      }
    },




    validateAll(){
      this.validateAccountName();
      this.validateEmail();
      this.validateAge();
      this.validateSex();
      this.validateInitialBalance();
      this.validateSavingGoal();

    },

    showDelModalFunc:function(e){
      this.$root.playClickSound();
      this.showDeleteModal=true;
      e.preventDefault();

    },

    closeDelModal(){
      this.$root.playClickSound();
      this.showDeleteModal=false;
      this.noadminleftwarning = false;
    },



  },
  watch: {
    avatarIdx(newVal, oldVal) {
      this.avatar = this.avatars[newVal];
      //console.log(this.avatar);
    }
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN ? userInfo.FAMILY_ADMIN : '0';
    this.userId = this.$route.query.userid;
    if(this.userId === 'me') this.userId = this.userInfo.ID;

    if(this.isAdmin === '0' && this.userId !== this.userInfo.ID){
      this.$root.gotoRoute({ path: '/noaccess'})
    }

  },
  mounted() {
    document.title = "Manage User";
    if(this.userId){
      this.pageMode = 'update';
      this.pageName = 'Edit a Family Member';
      this.getUser(this.userId);
      this.submitBtnTxt = 'Update';
    }else{
      this.pageMode = 'new';
      this.pageName = 'Add a Family Member';
      this.submitBtnTxt = 'Add';
    }
  },

}
</script>

<style scoped>
.form-group{
  margin:15px 0;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.small, small {
  font-size: 80% !important;
  font-weight: 400;
}

.required:after {
  content: " *";
  color: red;
}

.carousel img{
  width:150px;
}

.avatarDiv{
  margin:50px 0;
}



.credits{
  color:#ffd603;
  text-decoration: underline;
}

.delBtn{
  margin-left:10px;
}

/*modal*/
.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}


.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

#errorDiv{
  margin-top:20px;
  font-size:10px;
  color:red;
  padding:0 10px;
}



</style>

<style>
.carousel__prev{
  left:20px  !important;;
}

.carousel__next{
  right:20px  !important;;
}

.carousel__prev, .carousel__next{
  background-color: #0dcaf0 !important;
}
</style>
